<template>
  <section>
    <section class="top-bg_">
      <v-container>
        <v-row class="justify-center" dense>
          <v-col cols="12" xs="12" sm="6" md="4">
            <v-card class="pa-4 panel mt-15" elevation="1" shaped>
              <v-card-text>
                <v-row class="justify-left grey--text mb-6" dense>
                  <div class="display-1">Sign in</div>
                </v-row>
                <v-form
                  ref="loginForm"
                  v-model="valid"
                  @submit.prevent="submit"
                  action
                >
                  <div>
                    <div class="txt-titles">Email Address</div>
                    <v-text-field
                      dense
                      v-model="userModel.email"
                      :rules="emailRules"
                      :disabled="loading"
                      validate-on-blur
                      rounded
                      autofocus
                      class="text-field"
                      outlined
                      name="email"
                      type="email"
                    ></v-text-field>
                  </div>
                  <div>
                    <div class="txt-titles">Password</div>
                    <v-text-field
                      dense
                      v-model="userModel.password"
                      :rules="passwordRules"
                      :disabled="loading"
                      validate-on-blur
                      rounded
                      outlined
                      name="password"
                      type="password"
                    ></v-text-field>
                  </div>
                  <v-btn
                    :loading="loading"
                    type="submit"
                    rounded
                    class="my-6"
                    color="primary"
                    block
                    x-large
                    >Sign in</v-btn
                  >
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </section>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import { mapActions } from "vuex";

export default {
  created() {
    // firebase.auth().useEmulator("http://localhost:9099/");
  },
  methods: {
    ...mapActions(["showMessage"]),
    validate: function () {
      this.$refs.loginForm.validate();
    },
    submit: function () {
      this.validate();
      this.loading = true;

      setTimeout(async () => {
        let errMsg = "";
        if (!this.valid) {
          errMsg = "Please fix issues with sign in information";
          this.showMessage({ text: errMsg, error: true });
          this.loading = false;
        } else {
          try {
            await firebase
              .auth()
              .signInWithEmailAndPassword(
                this.userModel.email,
                this.userModel.password
              );
            // console.log("user===>>", resp.data.account);
            this.loading = false;
            // this.$router.replace({ name: "Home" });
          } catch (error) {
            console.error(error);
            if (
              error.code &&
              (error.code === "auth/wrong-password" ||
                error.code === "auth/user-not-found")
            ) {
              errMsg = "Invalid email or password";
            } else if (
              error.code &&
              error.code === "auth/network-request-failed"
            ) {
              errMsg = "Could not connect to server please try again";
            } else if (error.code && error.code === "auth/too-many-requests") {
              errMsg =
                "Too many attempts to login detected, please wait for some time before trying again";
            } else if (error.message == "network_error") {
              errMsg = "Could not connect to server please try again";
            } else if (
              error.response &&
              error.response.data &&
              error.response.data.message === "no_email_verify"
            ) {
              //   this.$router.replace({ name: "regConfirm" });
            } else if (
              error.response &&
              error.response.data &&
              error.response.data.message === "no_phone_verify"
            ) {
              //   this.$router.replace({ name: "phoneVerify" });
            } else {
              this.$store.dispatch("logout");
              errMsg = "Something went wrong, please try again."; //error.message || error.response.data.message;
            }
            this.showMessage({ text: errMsg, error: true });
            this.loading = false;
          }
        }
      }, 100);
    },
  },
  data() {
    return {
      valid: false,
      acceptTerms: false,
      loading: false,
      userModel: {
        email: "",
        password: "",
      },
      nameRules: [(v) => !!v || "This Field is required"],
      emailRules: [
        (v) => !!v || "Valid email address required",
        (v) =>
          /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Invalid email address",
      ],
      passwordRules: [
        (v) => !!v || "Password is required",
        //TODO: Enable password check in production
        // v => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,64}$/.test(v) || ""
      ],
    };
  },
};
</script>

<style lang="css" scoped>
.text-field {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
}
.reg-panel {
  padding: 20px 20px 40px 20px;
  margin-top: 30px;
  background-color: #eee;
  border-radius: 10px;
}
.top-bg {
  background: #f7483d;
  background-image: url("/img/bubbles.png"),
    linear-gradient(180deg, #f39a4a 0%, #f7483d 100%);
  background-repeat: no-repeat;
  /* min-height: 200px; */
  padding-top: 30px;
  padding-bottom: 40px;
  /* margin-bottom: 80px; */
  /* height: 80vh; */
  /* background-position: -20% -120px; */
  /* background-size: contain; */
  position: relative;
}
.txt-titles {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  padding-bottom: 5px;
}
.reg-txt {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  line-height: 36px;
  padding-bottom: 5px;
}
.top-bg svg {
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  /* height: 100px; */
}
.panel {
  margin-bottom: 300px;
}
.panel-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #555;
  text-align: center;
}
.bongo-logo {
  width: 100%;
  height: 100%;
  min-width: 300px;
  min-height: 200px;
  background: url("/img/login_logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
}
@media screen and (max-width: 600px) {
  .bongo-logo {
    background-position: center center;
  }
}
</style>